import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref, watch } from "vue";
import { getProductList } from "@/api/ProductApi";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { debounce } from "lodash";
export default defineComponent({
    name: "home",
    components: {},
    setup: function () {
        var _this = this;
        var products = ref([]);
        var loading = ref(false);
        var product = ref();
        var fullProductRoute = ref("");
        var pagination = ref();
        var store = useStore();
        var route = useRoute();
        var width = ref(0);
        var loadMoreLoading = ref({
            load: false,
        });
        var currentInfinitePage = ref({
            page: 1,
            isEnd: false,
            totalPage: 0,
            search: "",
        });
        var getListOfProducts = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        // topLoadingToggle(true);
                        return [4 /*yield*/, getProductList(payload)
                                .then(function (res) {
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                                currentInfinitePage.value.totalPage = res.data.meta.last_page;
                                products.value = res.data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        // topLoadingToggle(true);
                        _a.sent();
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var currentPageChange = function (val) {
            getListOfProducts({
                page: val,
            });
        };
        watch(function () { return store.getters.isUserAuthenticated; }, function () {
            getListOfProducts({});
        });
        watch(function () { return route.query; }, function (query) {
            currentInfinitePage.value = {
                page: 1,
                isEnd: false,
                totalPage: 0,
                search: query === null || query === void 0 ? void 0 : query.search,
            };
            getListOfProducts({ keyword: query === null || query === void 0 ? void 0 : query.search });
        });
        var handleMouseEnter = function () {
            getInfiniteProductList(currentInfinitePage, products);
        };
        var handleScroll = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var element, scrollWidth, fixedHeight, isBottom;
            return __generator(this, function (_a) {
                element = e.currentTarget;
                scrollWidth = parseInt(element.scrollHeight) - parseInt(element.scrollTop);
                fixedHeight = parseInt(element.clientHeight);
                isBottom = scrollWidth >= fixedHeight - 1 && scrollWidth <= fixedHeight + 1;
                if (isBottom) {
                    if (!currentInfinitePage.value.isEnd) {
                        getInfiniteProductList(currentInfinitePage, products);
                    }
                }
                return [2 /*return*/];
            });
        }); };
        var getInfiniteProductList = debounce(function (currentInfinitePage, products) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        loadMoreLoading.value.load = true;
                        return [4 /*yield*/, getProductList({
                                page: ++currentInfinitePage.value.page,
                                keyword: (_a = currentInfinitePage.value) === null || _a === void 0 ? void 0 : _a.search,
                            })
                                .then(function (res) {
                                var _a;
                                (_a = products.value).push.apply(_a, res.data.data);
                                var lastPage = res.data.meta.last_page;
                                if (currentInfinitePage.value.page >= lastPage) {
                                    currentInfinitePage.value.isEnd = true;
                                    --currentInfinitePage.value.page;
                                }
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _b.sent();
                        loadMoreLoading.value.load = false;
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        onMounted(function () {
            var _a, _b;
            getListOfProducts({ page: 1, keyword: (_a = route.query) === null || _a === void 0 ? void 0 : _a.search });
            setCurrentPageBreadcrumbs((_b = route.params.from) !== null && _b !== void 0 ? _b : "Home", [
                "All Product",
            ]);
        });
        return {
            products: products,
            loading: loading,
            product: product,
            fullProductRoute: fullProductRoute,
            pagination: pagination,
            currentPageChange: currentPageChange,
            store: store,
            handleMouseEnter: handleMouseEnter,
            loadMoreLoading: loadMoreLoading,
            currentInfinitePage: currentInfinitePage,
            width: width,
            getInfiniteProductList: getInfiniteProductList,
            handleScroll: handleScroll,
        };
    },
});
